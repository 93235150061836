import { RoleEnum } from "enums/role-enum";
import _ from "lodash";

enum DynamicRole {
  Staff = "staff",
}

export enum StaffRoleEnum {
  STAFF = "staff",
  SUPERUSER = "superuser",
}

export const Role = { ...RoleEnum, ...DynamicRole };
export type Role = RoleEnum | DynamicRole;

export const Permission = {
  ORGANIZATION: {
    WRITE: "organization:write",
    READ: "organization:read",
    MIGRATE: "organization:migrate",
    INTEGRATIONS: {
      WRITE: "org_integrations:write",
      READ: "org_integrations:read",
    },
    BRAND: {
      WRITE: "organization_brand:write",
      READ: "organization_brand:read",
    },
  },
  USER: {
    READ: "user:read",
    INVITE: "user:invite",
    REMOVE: "user:remove",
    RESTORE: "user:restore",
    ROLES: "user:roles:write",
    PROFILE: "user:profile:write",
    SCHEDULING_SETTINGS: "user:scheduling_settings:write",
    NOTIFICATIONS_SETTINGS: "user:notifications_settings:write",
  },
  ANALYTICS: {
    READ: "analytics:read",
  },
  GUIDE: {
    READ: "guide:read",
    WRITE: "guide:write",
    CLEAN: "guide:clean",
    DELETE: "guide:delete",
    SEED: "guide:seed",
  },
  DEPARTMENT: {
    READ: "department:read",
  },
  JOB: {
    READ: "job:read",
    WRITE: "job:write",
    PROPAGATE: "job:propagate",
  },
  TAGS: {
    READ: "tags:read",
    WRITE: "tags:write",
  },
  TAG_GROUP: {
    READ: "tag_group:read",
    WRITE: "tag_group:write",
  },
  GUIDE_TEMPLATE: {
    READ: "guide_template:read",
    WRITE: "guide_template:write",
    PROPAGATE: "guide_template:propagate",
  },
  POST_TEMPLATE: {
    READ: "post_template:read",
    WRITE: "post_template:write",
  },
  DEVELOPER: "developer",
  RETOOL: {
    GUIDE_TEMPLATES_PROPAGATE: "guide_templates:propagate",
    SEED_TEMPLATES: "seed:templates",
    ONBOARD_ORGANIZATION: "onboard:organization",
    DEFAULT: "default",
  },
  SCHEDULED_INTERVIEWS: {
    SYNC: "scheduled_interviews:sync",
    TRANSFER: "scheduled_interviews:transfer",
    UPDATE_GOOGLE_EVENT: "scheduled_interviews:update_google_event",
  },
  FEEDBACK: {
    READ: "feedback:read",
  },
  CANDIDATE: {
    DELETE: "candidate:delete",
  },
  ATSSYNC: {
    READ: "atssync:read",
    DELETE: "atssync:delete",
  },
  SCHEDULING: {
    READ: "scheduling:read",
    WRITE: "scheduling:write",
  },
  API_KEYS: {
    READ: "api_keys:read",
    WRITE: "api_keys:write",
  },
  BOOKING_LINK: {
    READ: "booking_link:read",
    WRITE: "booking_link:write",
  },
  REPORTING: {
    READ: "reporting.read",
  },
} as const;

type Vals<T> = T[keyof T];
type Paths<T> = Vals<{
  [K in keyof T]: T[K] extends object ? Paths<T[K]> : T[K];
}>;
type Leaves<T> = Paths<T> extends infer R ? { [K in keyof R]: R[K] } : never;
export type PermissionType = Leaves<typeof Permission>;

export const RolePermissions: Record<Role, PermissionType[]> = {
  [Role.Staff]: [
    Permission.DEVELOPER,
    Permission.GUIDE.SEED,
    Permission.GUIDE.CLEAN,
    Permission.GUIDE.DELETE,
    Permission.CANDIDATE.DELETE,
    Permission.RETOOL.GUIDE_TEMPLATES_PROPAGATE,
    Permission.RETOOL.SEED_TEMPLATES,
    Permission.RETOOL.ONBOARD_ORGANIZATION,
    Permission.RETOOL.DEFAULT,
    Permission.SCHEDULED_INTERVIEWS.SYNC,
    Permission.JOB.PROPAGATE,
    Permission.ORGANIZATION.MIGRATE,
    Permission.ORGANIZATION.READ,
    Permission.FEEDBACK.READ,
    Permission.SCHEDULED_INTERVIEWS.TRANSFER,
    Permission.ATSSYNC.DELETE,
    Permission.API_KEYS.READ,
    Permission.API_KEYS.WRITE,
  ],
  [Role.Admin]: [
    Permission.ORGANIZATION.WRITE,
    Permission.ORGANIZATION.BRAND.WRITE,
    Permission.ORGANIZATION.BRAND.READ,
    Permission.ORGANIZATION.INTEGRATIONS.WRITE,
    Permission.ORGANIZATION.INTEGRATIONS.READ,
    Permission.USER.READ,
    Permission.USER.INVITE,
    Permission.USER.PROFILE,
    Permission.USER.ROLES,
    Permission.USER.REMOVE,
    Permission.USER.RESTORE,
    Permission.USER.SCHEDULING_SETTINGS,
    Permission.USER.NOTIFICATIONS_SETTINGS,
    Permission.DEPARTMENT.READ,
    Permission.JOB.READ,
    Permission.JOB.WRITE,
    Permission.TAGS.READ,
    Permission.TAGS.WRITE,
    Permission.TAG_GROUP.READ,
    Permission.TAG_GROUP.WRITE,
    Permission.GUIDE_TEMPLATE.READ,
    Permission.GUIDE_TEMPLATE.WRITE,
    Permission.GUIDE_TEMPLATE.READ,
    Permission.ANALYTICS.READ,
    Permission.GUIDE.READ,
    Permission.GUIDE.WRITE,
    Permission.POST_TEMPLATE.READ,
    Permission.POST_TEMPLATE.WRITE,
    Permission.ATSSYNC.READ,
    Permission.ORGANIZATION.READ,
    Permission.FEEDBACK.READ,
    Permission.SCHEDULING.READ,
    Permission.SCHEDULING.WRITE,
    Permission.SCHEDULED_INTERVIEWS.UPDATE_GOOGLE_EVENT,
    Permission.SCHEDULED_INTERVIEWS.TRANSFER,
    Permission.API_KEYS.READ,
    Permission.API_KEYS.WRITE,
    Permission.BOOKING_LINK.READ,
    Permission.BOOKING_LINK.WRITE,
    Permission.REPORTING.READ,
  ],
  [Role.Manager]: [
    Permission.ORGANIZATION.INTEGRATIONS.READ,
    Permission.ORGANIZATION.WRITE,
    Permission.ORGANIZATION.BRAND.READ,
    Permission.USER.READ,
    Permission.USER.PROFILE,
    Permission.USER.INVITE,
    Permission.USER.ROLES,
    Permission.USER.SCHEDULING_SETTINGS,
    Permission.USER.NOTIFICATIONS_SETTINGS,
    Permission.DEPARTMENT.READ,
    Permission.JOB.READ,
    Permission.JOB.WRITE,
    Permission.TAGS.READ,
    Permission.TAGS.WRITE,
    Permission.TAG_GROUP.READ,
    Permission.TAG_GROUP.WRITE,
    Permission.GUIDE_TEMPLATE.READ,
    Permission.GUIDE_TEMPLATE.WRITE,
    Permission.GUIDE_TEMPLATE.READ,
    Permission.ANALYTICS.READ,
    Permission.GUIDE.READ,
    Permission.GUIDE.WRITE,
    Permission.POST_TEMPLATE.READ,
    Permission.POST_TEMPLATE.WRITE,
    Permission.ATSSYNC.READ,
    Permission.ORGANIZATION.READ,
    Permission.FEEDBACK.READ,
    Permission.SCHEDULING.READ,
    Permission.SCHEDULING.WRITE,
    Permission.SCHEDULED_INTERVIEWS.UPDATE_GOOGLE_EVENT,
    Permission.SCHEDULED_INTERVIEWS.TRANSFER,
    Permission.BOOKING_LINK.READ,
    Permission.BOOKING_LINK.WRITE,
    Permission.REPORTING.READ,
  ],
  [Role.Member]: [
    Permission.USER.READ,
    Permission.USER.PROFILE,
    Permission.USER.SCHEDULING_SETTINGS,
    Permission.USER.NOTIFICATIONS_SETTINGS,
    Permission.DEPARTMENT.READ,
    Permission.JOB.READ,
    Permission.JOB.WRITE,
    Permission.TAGS.READ,
    Permission.TAGS.WRITE,
    Permission.TAG_GROUP.READ,
    Permission.TAG_GROUP.WRITE,
    Permission.GUIDE_TEMPLATE.READ,
    Permission.POST_TEMPLATE.READ,
    Permission.POST_TEMPLATE.WRITE,
    Permission.ANALYTICS.READ,
    Permission.GUIDE.READ,
    Permission.GUIDE.WRITE,
    Permission.ATSSYNC.READ,
    Permission.ORGANIZATION.READ,
    Permission.FEEDBACK.READ,
    Permission.SCHEDULING.READ,
    Permission.SCHEDULING.WRITE,
    Permission.BOOKING_LINK.READ,
    Permission.BOOKING_LINK.WRITE,
    Permission.REPORTING.READ,
  ],
  [Role.Interviewer]: [
    Permission.USER.READ,
    Permission.USER.PROFILE,
    Permission.USER.SCHEDULING_SETTINGS,
    Permission.USER.NOTIFICATIONS_SETTINGS,
  ],
  [Role.External]: [],
  [Role.Candidate]: [],
};

export const isRoleAllowed = (
  roles: Role | Role[],
  moduleOrPermissions: PermissionType | PermissionType[]
) => {
  const permissions = [moduleOrPermissions].flat();

  // Candidates are not allowed to do anything
  if ([roles].every((role) => role === Role.Candidate)) {
    return false;
  }

  return permissions.every((permission) =>
    [roles].flat().some((role) => RolePermissions[role].includes(permission))
  );
};

export const OrderedRoles = [
  RoleEnum.Admin,
  RoleEnum.Manager,
  RoleEnum.Member,
  RoleEnum.Interviewer,
  RoleEnum.External, // not technically above candidate
  RoleEnum.Candidate,
] as RoleEnum[];

// TODO: This function is potentially problematic, as it assumes that each role
// is a superset of all lower roles; and that this will not change.
export const getHighestRole = (roles: RoleEnum[]) =>
  _(roles)
    .intersection(OrderedRoles)
    .sortBy((role: string) => _.indexOf(OrderedRoles, role))
    .first();

export const RoleCanChangeMapping: {
  [k in RoleEnum]: RoleEnum[];
} = {
  [RoleEnum.Admin]: [
    RoleEnum.Admin,
    RoleEnum.Manager,
    RoleEnum.Member,
    RoleEnum.Interviewer,
  ],
  [RoleEnum.Manager]: [RoleEnum.Manager, RoleEnum.Member, RoleEnum.Interviewer],
  [RoleEnum.Member]: [RoleEnum.Member, RoleEnum.Interviewer],
  [RoleEnum.Interviewer]: [RoleEnum.Interviewer],
  [RoleEnum.External]: [],
  [RoleEnum.Candidate]: [],
};

export const canChangeRole = ({
  highestRole,
  roleToChange,
}: {
  highestRole: RoleEnum;
  roleToChange: RoleEnum;
}) => {
  return RoleCanChangeMapping[highestRole].includes(roleToChange);
};
