import { match } from "path-to-regexp";

export const DEFAULT_ROUTE_MATCHING = [
  {
    matcher: match("/_error"),
    name: "_error",
    title: "_error",
  },
  {
    matcher: match("/404"),
    name: "404",
    title: "404",
  },
  {
    matcher: match("/500"),
    name: "500",
    title: "500",
  },
  {
    matcher: match("/account"),
    name: "Account",
    title: "Account",
  },
  {
    matcher: match("/account/notifications"),
    name: "Account Notifications",
    title: "Account Notifications",
  },
  {
    matcher: match("/account/preferences"),
    name: "Account Preferences",
    title: "Account Preferences",
  },
  {
    matcher: match("/account/profile"),
    name: "Account Profile",
    title: "Account Profile",
  },
  {
    matcher: match("/account/settings"),
    name: "Account Settings",
    title: "Account Settings",
  },
  {
    matcher: match("/booking-links"),
    name: "Booking Links",
    title: "Booking Links",
  },
  {
    matcher: match("/candidate-redirect"),
    name: "Candidate Redirect",
    title: "Candidate Redirect",
  },
  {
    matcher: match("/candidates"),
    name: "Candidates",
    title: "Candidates",
  },
  {
    matcher: match("/close"),
    name: "Close",
    title: "Close",
  },
  {
    matcher: match("/cookies-required"),
    name: "Cookies Required",
    title: "Cookies Required",
  },
  {
    matcher: match("/dev/ariakit"),
    name: "Dev Ariakit",
    title: "Dev Ariakit",
  },
  {
    matcher: match("/dev/content-editor/:guideId"),
    name: "Dev Content Editor",
    title: "Dev Content Editor",
  },
  {
    matcher: match("/dev/debugging-two"),
    name: "Dev Debugging Two",
    title: "Dev Debugging Two",
  },
  {
    matcher: match("/dev/debugging"),
    name: "Dev Debugging",
    title: "Dev Debugging",
  },
  {
    matcher: match("/dev/emails/:guideId"),
    name: "Dev Emails",
    title: "Dev Emails",
  },
  {
    matcher: match("/dev/emails/send-via-sendgrid"),
    name: "Dev Email Send Via Sendgrid",
    title: "Dev Email Send Via Sendgrid",
  },
  {
    matcher: match("/dev/ws-playground"),
    name: "Dev Ws Playground",
    title: "Dev Ws Playground",
  },
  {
    matcher: match("/extension/guide/:guideId"),
    name: "Extension Guide",
    title: "Extension Guide",
  },
  {
    matcher: match("/extension"),
    name: "Extension",
    title: "Extension",
  },
  {
    matcher: match("/extension/modals"),
    name: "Extension Modals",
    title: "Extension Modals",
  },
  {
    matcher: match("/google-calendar-permissions"),
    name: "Google Calendar Permissions",
    title: "Google Calendar Permissions",
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId"),
    name: "Guide",
    title: "Guide",
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/interviews"),
    name: "Guide Interviews",
    title: "Guide Interviews",
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/interviews/past"),
    name: "Guide Interview Past",
    title: "Guide Interview Past",
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/message/:messageId"),
    name: "Guide Message",
    title: "Guide Message",
  },
  {
    matcher: match("/guide/:organizationSlug/:shortId/messages"),
    name: "Guide Messages",
    title: "Guide Messages",
  },
  {
    matcher: match("/incomplete-setup"),
    name: "Incomplete Setup",
    title: "Incomplete Setup",
  },
  {
    matcher: match("/"),
    name: "",
    title: "",
  },
  {
    matcher: match("/internal/ai-tools/message-finder"),
    name: "Internal Ai Tool Message Finder",
    title: "Internal Ai Tool Message Finder",
  },
  {
    matcher: match("/internal/ai-tools/prompt-builder"),
    name: "Internal Ai Tool Prompt Builder",
    title: "Internal Ai Tool Prompt Builder",
  },
  {
    matcher: match("/internal/algorithm/interviewer-times"),
    name: "Internal Algorithm Interviewer Times",
    title: "Internal Algorithm Interviewer Times",
  },
  {
    matcher: match("/internal/atssync"),
    name: "Internal Atssync",
    title: "Internal Atssync",
  },
  {
    matcher: match("/internal/backfill-migrator"),
    name: "Internal Backfill Migrator",
    title: "Internal Backfill Migrator",
  },
  {
    matcher: match("/internal/emails"),
    name: "Internal Emails",
    title: "Internal Emails",
  },
  {
    matcher: match("/internal/encryption"),
    name: "Internal Encryption",
    title: "Internal Encryption",
  },
  {
    matcher: match("/internal/google"),
    name: "Internal Google",
    title: "Internal Google",
  },
  {
    matcher: match("/internal/greenhouse"),
    name: "Internal Greenhouse",
    title: "Internal Greenhouse",
  },
  {
    matcher: match("/internal/guide-url-lookup"),
    name: "Internal Guide Url Lookup",
    title: "Internal Guide Url Lookup",
  },
  {
    matcher: match("/internal/guide/:organizationSlug/:shortId"),
    name: "Internal Guide",
    title: "Internal Guide",
  },
  {
    matcher: match("/internal/history"),
    name: "Internal History",
    title: "Internal History",
  },
  {
    matcher: match("/internal/import-profiles"),
    name: "Internal Import Profiles",
    title: "Internal Import Profiles",
  },
  {
    matcher: match("/internal"),
    name: "Internal",
    title: "Internal",
  },
  {
    matcher: match("/internal/lookups"),
    name: "Internal Lookups",
    title: "Internal Lookups",
  },
  {
    matcher: match("/internal/queues"),
    name: "Internal Queues",
    title: "Internal Queues",
  },
  {
    matcher: match("/internal/scheduler-v2"),
    name: "Internal Scheduler V2",
    title: "Internal Scheduler V2",
  },
  {
    matcher: match("/internal/scheduling-requests"),
    name: "Internal Scheduling Requests",
    title: "Internal Scheduling Requests",
  },
  {
    matcher: match("/internal/testing/hanging-requests"),
    name: "Internal Testing Hanging Requests",
    title: "Internal Testing Hanging Requests",
  },
  {
    matcher: match("/internal/training-cleanup/import-activity"),
    name: "Internal Training Cleanup Import Activity",
    title: "Internal Training Cleanup Import Activity",
  },
  {
    matcher: match("/internal/training-cleanup"),
    name: "Internal Training Cleanup",
    title: "Internal Training Cleanup",
  },
  {
    matcher: match("/internal/zoom"),
    name: "Internal Zoom",
    title: "Internal Zoom",
  },
  {
    matcher: match("/interviewers/pools/:poolId/qualified"),
    name: "Interviewer Pool Qualified",
    title: "Interviewer Pool Qualified",
  },
  {
    matcher: match("/interviewers/pools/:poolId/training"),
    name: "Interviewer Pool Training",
    title: "Interviewer Pool Training",
  },
  {
    matcher: match("/interviewers/pools"),
    name: "Interviewer Pools",
    title: "Interviewer Pools",
  },
  {
    matcher: match("/invalid-permissions"),
    name: "Invalid Permissions",
    title: "Invalid Permissions",
  },
  {
    matcher: match("/invalid-plan"),
    name: "Invalid Plan",
    title: "Invalid Plan",
  },
  {
    matcher: match("/jobs/:jobId/interview-plan"),
    name: "Job Interview Plan",
    title: "Job Interview Plan",
  },
  {
    matcher: match("/jobs/:jobId/interviews"),
    name: "Job Interviews",
    title: "Job Interviews",
  },
  {
    matcher: match("/jobs/:jobId/settings"),
    name: "Job Settings",
    title: "Job Settings",
  },
  {
    matcher: match("/jobs/:jobId/stages"),
    name: "Job Stages",
    title: "Job Stages",
  },
  {
    matcher: match("/jobs"),
    name: "Jobs",
    title: "Jobs",
  },
  {
    matcher: match("/login-popup"),
    name: "Login Popup",
    title: "Login Popup",
  },
  {
    matcher: match("/login"),
    name: "Login",
    title: "Login",
  },
  {
    matcher: match("/new-user"),
    name: "New User",
    title: "New User",
  },
  {
    matcher: match("/reports/interviewers"),
    name: "Report Interviewers",
    title: "Report Interviewers",
  },
  {
    matcher: match("/reports/recruiting-team"),
    name: "Report Recruiting Team",
    title: "Report Recruiting Team",
  },
  {
    matcher: match("/reports/surveys"),
    name: "Report Surveys",
    title: "Report Surveys",
  },
  {
    matcher: match("/reports/training/events"),
    name: "Report Training Events",
    title: "Report Training Events",
  },
  {
    matcher: match("/reports/training/load"),
    name: "Report Training Load",
    title: "Report Training Load",
  },
  {
    matcher: match("/scheduling-requests"),
    name: "Scheduling Requests",
    title: "Scheduling Requests",
  },
  {
    matcher: match("/scheduling/:bookingLinkId/:bookingLinkSlug"),
    name: "Scheduling",
    title: "Scheduling",
  },
  {
    matcher: match("/scheduling/bookings/:bookingLinkSubmissionId"),
    name: "Scheduling Bookings",
    title: "Scheduling Bookings",
  },
  {
    matcher: match("/settings/candidate-portals"),
    name: "Candidate Portal Settings",
    title: "Candidate Portal Settings",
  },
  {
    matcher: match("/settings/company"),
    name: "Company Settings",
    title: "Company Settings",
  },
  {
    matcher: match("/settings/configuration"),
    name: "Configuration Settings",
    title: "Configuration Settings",
  },
  {
    matcher: match("/settings"),
    name: "Settings",
    title: "Settings",
  },
  {
    matcher: match("/settings/integrations"),
    name: "Integration Settings",
    title: "Integration Settings",
  },
  {
    matcher: match("/settings/interviews"),
    name: "Interview Settings",
    title: "Interview Settings",
  },
  {
    matcher: match("/settings/scheduling"),
    name: "Scheduling Settings",
    title: "Scheduling Settings",
  },
  {
    matcher: match("/settings/tags"),
    name: "Tag Settings",
    title: "Tag Settings",
  },
  {
    matcher: match("/settings/users"),
    name: "User Settings",
    title: "User Settings",
  },
  {
    matcher: match("/signup"),
    name: "Signup",
    title: "Signup",
  },
  {
    matcher: match("/templates/guides/:guideTemplateId/stages"),
    name: "Guide Template Stages",
    title: "Guide Template Stages",
  },
  {
    matcher: match("/templates/guides"),
    name: "Guide Templates",
    title: "Guide Templates",
  },
  {
    matcher: match("/templates"),
    name: "Templates",
    title: "Templates",
  },
  {
    matcher: match("/templates/messages"),
    name: "Message Templates",
    title: "Message Templates",
  },
  {
    matcher: match("/upcoming-interviews"),
    name: "Upcoming Interviews",
    title: "Upcoming Interviews",
  },
  {
    matcher: match("/v1/self-scheduling/pitch-pages"),
    name: "V1 Self Scheduling Pitch Pages",
    title: "V1 Self Scheduling Pitch Pages",
  },
  {
    matcher: match("/v1/self-scheduling/scheduler/availabilities"),
    name: "V1 Self Scheduling Scheduler Availabilities",
    title: "V1 Self Scheduling Scheduler Availabilities",
  },
];