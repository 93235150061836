import { useLogEvent } from "analytics";
import { Events } from "analytics/types";
import config from "config";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import semver from "semver";
import inIframe from "utils/in-iframe";
import useWindowMessageListener, {
  ExtensionFrameVariant,
  Message as WindowMessage,
  OutboundMessage as OutboundWindowMessage,
} from "utils/useWindowMessageListener";

interface ExtensionControllerContextValue {
  panelOpenState: boolean;
  setPanelOpenState: (value: boolean) => void;
  sendMessageToParent: (message: OutboundWindowMessage) => void;
  updateAvailable?: string;
  extensionVersion?: string;
  parentHref: string | null;
  isModalFrame: boolean;
  isSidepanelFrame: boolean;
  hasExtensionBeenOpen: boolean;
  popoverWidthVariant: "default" | "wide";
  frameVariant?: ExtensionFrameVariant;
  setPopoverWidthVariant: (value: "default" | "wide") => void;
  isFakeExtensionFrame: boolean;
  setIsFakeExtensionFrame: (value: boolean) => void;
}

export const PopoverWidthVariants: Record<"default" | "wide", 512 | 576> = {
  default: 512,
  wide: 576,
};

// Needs to be above the user auth context so that user auth context gets access to panel open state
const ExtensionControllerContext = React.createContext<
  ExtensionControllerContextValue | undefined
>(undefined);

export const useExtensionController = () => {
  const context = useContext(ExtensionControllerContext);
  if (context === undefined) {
    throw new Error("ExtensionControllerContext must be initialized first.");
  }
  return context;
};

export const useOptionalExtensionController = () => {
  const context = useContext(ExtensionControllerContext);
  return context;
};

export const useExtensionIsClosed = () => {
  const context = useContext(ExtensionControllerContext);
  return (
    inIframe() && context && context.isSidepanelFrame && !context.panelOpenState
  );
};

export const useExtensionHasBeenOpened = () => {
  const context = useContext(ExtensionControllerContext);
  if (process.env.STORYBOOK || !inIframe() || context?.isModalFrame) {
    return true;
  }

  return !!context?.hasExtensionBeenOpen;
};

export function ExtensionControllerProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const logEvent = useLogEvent({ component: "ExtensionControllerProvider" });
  const [popoverWidthVariant, setPopoverWidthVariant] = useState<
    "default" | "wide"
  >("default");

  const isModalFrame = useRef(
    window.location.search.includes("sourceIframeId=guide-modals-v2") ||
      window.location.search.includes("sourceIframeId=guide-modals")
  );
  const isSidepanelFrame = useRef(
    window.location.search.includes("sourceIframeId=guide-sidepanel-v2") ||
      window.location.search.includes("sourceIframeId=guide-sidepanel")
  );
  const [currentParentHref, setCurrentParentHref] = useState<string | null>(
    null
  );

  const [hasExtensionBeenOpen, setHasExtensionBeenOpen] = useState(false);
  const [panelIsOpenInExtension, setPanelIsOpenInExtension] = useState(false);
  const [isFakeExtensionFrame, setIsFakeExtensionFrame] = useState(false);

  useEffect(() => {
    if (panelIsOpenInExtension) {
      setHasExtensionBeenOpen(true);
    }
  }, [panelIsOpenInExtension]);

  const sendMessageToParent = useCallback((message: OutboundWindowMessage) => {
    window.parent.parent.postMessage(message, "*");
  }, []);

  const [version, setVersion] = useState("");

  const frameVariant = useMemo(() => {
    if (!version) {
      return "fullscreen";
    }

    return semver.gte(version, "3.1.13") ? "fullscreen" : "popover";
  }, [version]);

  const setPanelOpenState = (newPanelOpenState: boolean) => {
    if (!inIframe()) {
      setPanelIsOpenInExtension(newPanelOpenState);
    } else {
      sendMessageToParent({
        command: "set-panel-open-state",
        value: newPanelOpenState,
      });
    }
  };

  useEffect(() => {
    // On mount, set the panel state
    const url = new URL(window.location.href);
    const guideOpenParam = url.searchParams.get("guide_open");
    if (guideOpenParam === "1") {
      setPanelOpenState(true);
    }
    // Also send legacy commands for old extension clients waiting
    // for permission to show buttons on the Greenhouse page
    sendMessageToParent("show-persistent-button");
    sendMessageToParent({
      command: "update-sidepanel-pointer-events",
      value: "none",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listener = (msg: WindowMessage) => {
    console.debug(`[Msg Received From Extension]`, msg);
    if (typeof msg.data === "object" && "command" in msg.data) {
      switch (msg.data.command) {
        case "version":
          setVersion(msg.data.value);
          break;
        case "panel-open-state":
          setPanelIsOpenInExtension(msg.data.value);
          break;
        case "parent-href":
          setCurrentParentHref(msg.data.value);
          break;
        case "track-event":
          logEvent(
            msg.data.value.eventName as Events,
            msg.data.value.eventProperties
          );
          break;
        default:
      }
    }
  };

  useWindowMessageListener(
    listener,
    `chrome-extension://${config.EXTENSION_ID}`
  );

  const internalToolingListener = (msg: WindowMessage) => {
    console.debug(`[Msg Received From Extension]`, msg);
    if (typeof msg.data === "object" && "command" in msg.data) {
      switch (msg.data.command) {
        case "co2-panel-open-state":
          setPanelIsOpenInExtension(msg.data.value);
          break;
        default:
      }
    }
  };

  useWindowMessageListener(internalToolingListener, "*");

  useEffect(() => {
    sendMessageToParent({ command: "request-version" });
    sendMessageToParent({ command: "request-panel-open-state" });
    sendMessageToParent({ type: "request-parent-href" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendMessageToParent({
      command: "set-panel-width",
      value: PopoverWidthVariants[popoverWidthVariant],
    });
  }, [sendMessageToParent, popoverWidthVariant]);

  useEffect(() => {
    sendMessageToParent({ command: "show-message-button" });
  }, [sendMessageToParent]);

  useEffect(() => {
    if (typeof window.FS !== "undefined" && inIframe() && isSidepanelFrame) {
      if (panelIsOpenInExtension) {
        console.debug("Starting fullstory...", {
          location: "ExtensionControllerProvider.tsx",
        });
        window.FS.restart();
      } else {
        console.debug("Shutting down fullstory...", {
          location: "ExtensionControllerProvider.tsx",
        });
        window.FS.shutdown();
      }
    }
  }, [panelIsOpenInExtension]);

  useEffect(() => {
    sendMessageToParent({ type: "request-parent-href" });
  }, [sendMessageToParent]);

  return (
    <ExtensionControllerContext.Provider
      value={{
        panelOpenState: panelIsOpenInExtension,
        setPanelOpenState,
        sendMessageToParent,
        extensionVersion: version,
        parentHref: currentParentHref,
        isModalFrame: isModalFrame.current,
        isSidepanelFrame: isSidepanelFrame.current,
        hasExtensionBeenOpen,
        frameVariant,
        isFakeExtensionFrame,
        setIsFakeExtensionFrame,
        popoverWidthVariant,
        setPopoverWidthVariant,
      }}
    >
      {children}
    </ExtensionControllerContext.Provider>
  );
}
